<template>
  <div class="flex flex-wrap w-full">
    <div v-for="playlist in playlists" class="flex flex-wrap w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-2 bg-gray-100">
      <img src="@/assets/placeholder.png" @click="go(playlist.id)" class="w-full h-64 object-cover cursor-pointer">
      <p class="">{{ playlist.title }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import router from "@/router";

export default {
  data: function () {
    return {
      playlists: null
    }
  },
  methods: {
    go(id) {
      console.log("id", id)
      router.push({ name: 'playlist', params: { id: id } })
    }
  },
  async mounted() {
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/playlists`);
      this.playlists = response.data
      console.log(this.playlists)
    } catch (error) {
      console.error(error);
    }
  }
}
</script>
