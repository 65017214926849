<template>
  <div class="flex flex-wrap w-full">
    <div v-for="video in playlist" class="flex flex-wrap w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-2 bg-gray-100">
      <img :src="video.image" @click="open_link(video.url)" class="w-full h-64 object-cover cursor-pointer">
      <p class="">{{ video.title.split("|")[0] }}</p>
    </div>
  </div>
  <div class="flex flex-wrap w-full">
    <form class="w-full">
      <div class="flex items-center py-2">
        <input v-model="url" class="w-full border border-black p-3" type="text">
        <button @click="add" class="border border-black py-3 px-9" type="button">
          Add
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data: function () {
    return {
      playlist: null,
      url: null
    }
  },
  methods: {
    open_link(url) {
      window.open(url, "_blank");
    },
    async get() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/playlist/${this.$route.params.id}`);
        this.playlist = response.data
        console.log(this.playlist)
      } catch (error) {
        console.error(error);
      }
    },
    async add() {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/playlist/${this.$route.params.id}`, {
          url: this.url
        });
        console.log("url", this.url)
        this.url = null
        await this.get()
      } catch (error) {
        console.error(error);
      }
    },
  },
  async mounted() {
    this.get()
  }
}
</script>
