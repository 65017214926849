<template>
  <div class="flex flex-wrap w-full">
    <form class="w-full">
      <div class="flex items-center border-b border-teal-500 py-2">
        <input v-model="url" class="w-full" type="text">
        <button @click="add" class="" type="button">
          Add
        </button>
        <button @click="clear" class="" type="button">
          Clear
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data: function () {
    return {
      url: null
    }
  },
  methods: {
    async add() {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/`, {
          url: this.url
        });
        console.log("url", this.url)
      } catch (error) {
        console.error(error);
      }
    },
    clear() {
      this.url = null
    }
  }
}
</script>
