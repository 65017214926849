<template>
  <div class="flex flex-wrap w-full">
    <div class="flex flex-wrap w-full p-2">
      <span class="pr-2 font-bold">Most saved: </span>
      <button class="bg-blue-700 hover:bg-blue-700 text-white font-bold px-1 rounded mr-1">
        Today
      </button>
      <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold px-1 rounded mr-1">
        This week
      </button>
      <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold px-1 rounded mr-1">
        This month
      </button>
      <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold px-1 rounded mr-1">
        This year
      </button>
      <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold px-1 rounded mr-1">
        All time
      </button>
    </div>
    
    <div v-for="video in videos" class="flex flex-wrap w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-2 bg-gray-100">
      <img :src="video.image" @click="open_link(video.url)" class="w-full h-64 object-cover cursor-pointer">
      <p class="">{{ video.title.split("|")[0] }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data: function () {
    return {
      videos: null
    }
  },
  methods: {
    open_link(url) {
      window.open(url, "_blank");
    }
  },
  async mounted() {
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/`);
      this.videos = response.data
      console.log(this.videos)
    } catch (error) {
      console.error(error);
    }
  }
}
</script>
